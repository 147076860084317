<template>
  <b-card>
    <template
      #header
      class="mb-0"
    >
      <span class="ml-1 font-weight-bold">
        <feather-icon
          icon="MessageSquareIcon"
        />
        Session Details
      </span>
    </template>

    <b-row>
      <b-col md="6">
        <div>
          <span class="font-weight-bold">Address</span>
          <p class="text-capitalize">
            {{ session.session_address }}
          </p>
        </div>
        <div>
          <span class="font-weight-bold">Title</span>
          <p class="text-capitalize">
            {{ session.session_provider }}
          </p>
        </div>
        <div>
          <span class="font-weight-bold">Language</span>
          <p class="text-capitalize">
            {{ session.session_languages }}
          </p>
        </div>
        <div>
          <span class="font-weight-bold">Gender</span>
          <p class="text-capitalize">
            {{ session.session_target_gender }}
          </p>
        </div>
      </b-col>
      <b-col md="6">
        <div class="mb-2">
          <span class="font-weight-bold">Start Time: {{ session.session_start }}</span>
        </div>
        <div>
          <span class="font-weight-bold">End Time: {{ session.session_end }}</span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  name: 'SessionDetails',
  props: {
    session: { type: Object, default: () => [] },
  },
}
</script>
<style lang="">

</style>
